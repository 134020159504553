import React from "react"
import { Box, Container, Image, Text, Stack, VStack, Heading } from "@chakra-ui/react"
import { useCore } from "@app/hooks/useCore"
import type { Props } from "@app/templates/store"
import type { PageProps } from "@root/types/global"
import Title from "@app/components/Sections/Title/Title"
import Map from "@app/components/Map"

const Store: React.FC<PageProps<Props>> = ({ store }) => {
  const {
    helpers: { sanityContent },
  } = useCore()

  const item = {
    ...store,
    image: {
      alt: store?.image?.alt || "",
      src: store?.image?.asset?.url || "",
    },
    content: sanityContent(store?.content),
  }
  const data = [
    {
      id: "store",
      location: item.location,
      title: item.title,
      address: item.address,
    },
  ]
  return (
    <>
      <Title title={item?.title} />
      <Container maxW="container.xl" pb={16} px={[4, 16]}>
        {item?.image?.src && <Image boxSize="100%" objectFit="cover" src={item?.image?.src} alt={item?.image?.alt} my={8} />}
        <Stack direction={["column-reverse", "row"]} alignItems="start">
          <Box w={["100%", "40%"]} overflow="auto" pr={[0, 8]} mt={[8, 0]}>
            {item?.address && (
              <VStack mb={6} align="start" spacing={4}>
                <Heading as="h4" size="h5" fontWeight="800">
                  Address
                </Heading>
                <Box>
                  <Text size="14">{item?.address}</Text>
                  {store?.suburb && <Text size="14">{store?.suburb}</Text>}
                  {store?.state && (
                    <Text size="14">
                      {store?.state}, {store?.postcode}
                    </Text>
                  )}
                </Box>
              </VStack>
            )}
            {item?.phone && (
              <VStack mb={6} align="start" spacing={4}>
                <Heading as="h4" size="h5" fontWeight="800">
                  Phone
                </Heading>
                <Text size="14">Tel: {item?.phone}</Text>
              </VStack>
            )}
            {item?.email && (
              <VStack mb={6} align="start" spacing={4}>
                <Heading as="h4" size="h5">
                  Email
                </Heading>
                <Text size="14">{item?.email}</Text>
              </VStack>
            )}
            {item?.openhours?.length > 0 && (
              <VStack mb={6} align="start">
                <Heading as="h4" size="h5" fontWeight="800">
                  Trading Hours
                </Heading>
                {item?.openhours.map((openhour, index) => (
                  <Text size="14" key={index}>
                    {openhour.day}: {openhour.hours}
                  </Text>
                ))}
              </VStack>
            )}
            {item?.description && (
              <Text size="14" mb={6}>
                {item?.description}
              </Text>
            )}
            <Box align="left" mb={8}>
              {item?.content}
            </Box>
          </Box>
          <Box height={["400px", "640px"]} w={["100%", "60%"]}>
            <Map data={data} showStoreLink={false} />
          </Box>
        </Stack>
      </Container>
    </>
  )
}

export default Store
