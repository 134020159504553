import React from "react"
import { graphql, PageProps } from "gatsby"
import Template from "@app/components/Store/Store"

export type Props = PageProps<GatsbyTypes.TemplateStoreQuery, GatsbyTypes.TemplateStoreQueryVariables>

export const query = graphql`
  query TemplateStore($id: String!) {
    store: sanityStore(_id: { eq: $id }) {
      ...SanityStoreFragment
    }
    template: sanityTemplateStore {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      additionalRelatedText
      settingShowRelated
    }
  }
  fragment SanityStoreFragment on SanityStore {
    title
    description
    handle {
      current
    }
    url
    phone
    email
    address
    suburb
    state
    postcode
    openhours {
      day
      hours
    }
    location {
      lat
      lng
    }
    ap21Number
    metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    content: _rawContent(resolveReferences: { maxDepth: 4 })
    image: _rawImage(resolveReferences: { maxDepth: 2 })
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Template {...props} {...data} />
export default Component
